import { getActiveSplitTests, getSplitTestCookieOptions, getSplitTestCookieString, getVariant } from '~/configuration/split-testing.configuration'

function setQueryState(stateName: string, queryName?: string) {
  const route = useRoute()
  const queryValue = route.query[queryName || stateName]
  const value = Array.isArray(queryValue) ? queryValue[0] : queryValue
  useState(stateName, () => value)
}

export default defineNuxtPlugin({
  name: 'set-cookies-fallback',
  parallel: true,
  dependsOn: ['storefront'],
  setup() {
    const { $currentStorefront } = useNuxtApp()
    const { storefrontCode } = $currentStorefront

    // Set UTM states
    setQueryState('utm_campaign')
    setQueryState('utm_medium')
    setQueryState('utm_content')

    // Apply Split Testing cookies
    const activeSplitTests = getActiveSplitTests(storefrontCode)

    for (const test of activeSplitTests) {
      const cookieString = getSplitTestCookieString(test.id)
      const splitTestCookie = useCookie(cookieString, getSplitTestCookieOptions(test))

      if (!splitTestCookie.value) {
        const stateVariant = useSplitTestState(test.id)
        const variant = stateVariant.value || getVariant(test)

        // If we set the cookie on the server, it will get cached by ISR.
        if (import.meta.client)
          splitTestCookie.value = variant

        stateVariant.value = variant
      }
    }
  },
})
