import { USER_ID_COOKIE } from 'configuration/global.configuration'

import { getCookie } from 'lib/cookie'
import { getDisplayVariant } from 'lib/product'

import type { Order } from 'types/models/order'
import type { CartItem } from 'types/models/cart.model'
import { isCartItem } from 'types/models/cart.model'
import type { Product, ProductVariant } from 'types/models/product'
import {
  getListFromRoute,
  getSplitTestVariants,
  getXoWidgetId,
  normalizeCartItem,
  normalizeProductItem,
  processCartData,
  processImpressionData,
} from './helpers'
import type {
  AddToCartEvent,
  CheckoutCustomerEmailUpdateEvent,
  CheckoutEvent,
  ClickSearchSuggestionsEvent,
  CloseProductPageEvent,
  FilterClickEvent,
  GeneralInfoEvent,
  NewsletterSignupEvent,
  OrderCompleteEvent,
  OrderDiscountCodeEvent,
  PageBlockClickEvent,
  PaymentCancelEvent,
  ProductClickEvent,
  ProductDetailEvent,
  ProductImpressionEvent,
  ProductPageEvent,
  PromotionEvent,
  PurchaseEvent,
  RemoveFromCartEvent,
  SpaPageViewEvent,
  UpdateCartEvent,
} from '~/types/gtm'
import {
  ProductPageEvents,
  PromotionEvents,
} from '~/types/gtm'
import type { RouteLocationNormalized } from '#vue-router'
import { getRouteTypeFromURL } from '~/lib/routing'
import type { CheckoutView } from '~/types/checkout'

export function purchasePayload(order: Order): PurchaseEvent {
  const ratesStore = useRatesStore()
  const discountStore = useDiscountCodeStore()
  const storefrontStore = useStorefrontStore()

  return {
    event: 'purchase',
    pageType: 'SUCCESS',
    countryCode: storefrontStore.currentMarket.countryCode,
    language: order.market.language,
    customerId: getCookie(USER_ID_COOKIE) || '',
    customerEmail: order.contact.email,
    pageUrl: window.location.href,
    paymentMethod: order.payment.method,
    ecommerce: {
      purchase: {
        actionField: {
          id: order.orderNumber,
          revenue: order.priceTotal,
          revenueEur: ratesStore.convert(order.priceTotal, order.currency),
          tax: order.priceTax,
          shipping: order.priceShipping,
          paymentOption: order.payment.method,
          affiliation: undefined,
          discountCode: discountStore.code,
        },
        products: processCartData(order.items, order.currency),
        hasCompleted: true,
      },
      currencyCode: order.currency,
    },
  }
}

export function orderCompletePayload(order: Order): OrderCompleteEvent {
  const storefrontStore = useStorefrontStore()
  const {
    contact,
    address: { shipping },
  } = order

  return {
    event: 'orderComplete',
    countryCode: storefrontStore.currentMarket.countryCode,
    orderValue: order.priceTotal,
    orderCurrency: order.currency,
    orderId: order.orderNumber,
    paymentMethod: order.payment.method,
    ecData: {
      email: contact.email,
      phoneNumber: contact.telephone,
      firstName: contact.firstName,
      lastName: contact.lastName,
      homeAddress: {
        street: `${shipping?.street0 || ''} ${shipping?.street1 || ''}`.trim(),
        city: shipping?.city || '',
        region: '',
        postalCode: shipping?.postCode || '',
        country: shipping?.country || '',
      },
    },
  }
}

export function paymentCancelPayload(): PaymentCancelEvent {
  const storefrontStore = useStorefrontStore()

  return {
    event: 'paymentCancel',
    countryCode: storefrontStore.currentMarket.countryCode,
    customerId: getCookie(USER_ID_COOKIE) || '',
  }
}

export function newsletterSignupPayload(eventLabel: string, email: string): NewsletterSignupEvent {
  const storefrontStore = useStorefrontStore()

  return {
    event: 'newsletterSignup',
    countryCode: storefrontStore.currentMarket.countryCode,
    eventInfo: {
      category: 'newsletter',
      action: 'onPage',
      label: eventLabel,
      email,
    },
  }
}

export function checkoutViewPayload(steps: Record<CheckoutView, number>, view: CheckoutView): CheckoutEvent {
  const storefrontStore = useStorefrontStore()

  return {
    event: 'checkout',
    countryCode: storefrontStore.currentMarket.countryCode,
    ecommerce: {
      checkout: {
        actionField: { step: steps[view] },
      },
    },
  }
}

export function checkoutCustomerEmailUpdatePayload(customerEmail: string): CheckoutCustomerEmailUpdateEvent {
  const storefrontStore = useStorefrontStore()

  return {
    event: 'checkout-customer-email-update',
    countryCode: storefrontStore.currentMarket.countryCode,
    customerEmail,
  }
}

export interface ProductPagePayloadArgs {
  action: keyof typeof ProductPageEvents
  label: ProductPageEvent['eventInfo']['label']
  sku?: ProductPageEvent['eventInfo']['sku']
}
export function productPagePayload({
  action,
  label,
  sku,
}: ProductPagePayloadArgs): ProductPageEvent {
  const storefrontStore = useStorefrontStore()
  return {
    event: ProductPageEvents[action],
    countryCode: storefrontStore.currentMarket.countryCode,
    eventInfo: {
      category: 'productPage',
      action,
      label,
      sku,
    },
  }
}

export function closeProductPayload(pageUrl: string): CloseProductPageEvent {
  const storefrontStore = useStorefrontStore()
  return {
    event: 'closeProduct',
    countryCode: storefrontStore.currentMarket.countryCode,
    eventInfo: {
      category: 'productPage',
      action: 'closePopup',
    },
    pageUrl,
  }
}

export function removeFromCartPayload(item: CartItem, itemQty: number): RemoveFromCartEvent {
  const storefrontStore = useStorefrontStore()
  const currencyCode = storefrontStore.currentMarket.currency.code

  return {
    event: 'removeFromCart',
    countryCode: storefrontStore.currentMarket.countryCode,
    ecommerce: {
      currencyCode,
      remove: {
        products: [normalizeCartItem(item, itemQty, currencyCode)],
      },
    },
  }
}

export function addToCartPayload(item: CartItem | Product, variant?: ProductVariant): AddToCartEvent {
  const products = []
  const storefrontStore = useStorefrontStore()
  const currency = storefrontStore.currency

  if (isCartItem(item))
    products.push(normalizeCartItem(item, 1, currency.code))
  else if (variant)
    products.push(normalizeProductItem(item, variant, 1, '', currency))

  return {
    event: 'addToCart',
    customerId: getCookie(USER_ID_COOKIE) || '',
    countryCode: storefrontStore.currentMarket.countryCode,
    ecommerce: {
      currencyCode: currency.code,
      add: {
        products,
      },
    },
  }
}

export function cartUpdatePayload(): UpdateCartEvent {
  const cartStore = useCartStore()
  const storefrontStore = useStorefrontStore()
  const ratesStore = useRatesStore()

  return {
    event: 'cart',
    countryCode: storefrontStore.currentMarket.countryCode,
    ecommerce: {
      cart: {
        total: cartStore.state.priceTotal,
        totalEur: ratesStore.convert(
          cartStore.state.priceTotal,
          storefrontStore.currentMarket.currency.code,
        ),
        products: processCartData(
          cartStore.state.items,
          storefrontStore.currentMarket.currency.code,
        ),
      },
    },
  }
}

export function orderDiscountCodePayload(discountCode: string): OrderDiscountCodeEvent {
  const cartStore = useCartStore()
  const storefrontStore = useStorefrontStore()

  return {
    event: 'orderDiscountCode',
    countryCode: storefrontStore.currentMarket.countryCode,
    discountCode,
    ecommerce: {
      products: processCartData(cartStore.state.items, storefrontStore.currentMarket.currency.code),
    },
  }
}

export interface PromoPayloadArgs {
  name: string
  position: number
  url: string
}
export function promoPayload(event: keyof typeof PromotionEvents, promo: PromoPayloadArgs): PromotionEvent {
  const storefrontStore = useStorefrontStore()
  return {
    event,
    countryCode: storefrontStore.currentMarket.countryCode,
    ecommerce: {
      [PromotionEvents[event]]: {
        promotions: [
          {
            id: promo.name,
            name: promo.name,
            creative: promo.url,
          },
        ],
      },
    },
  }
}

export interface ProductClickPayloadArgs {
  collectionIndex: number
  listInfo: string
  product: Product
  collectionContent: boolean
}

// GTM product click event
export function productClickPayload({
  product,
  collectionContent,
  collectionIndex,
  listInfo,
}: ProductClickPayloadArgs): ProductClickEvent {
  const storefrontStore = useStorefrontStore()
  const rootStore = useRootStore()
  const currency = storefrontStore.currency
  const variant = getDisplayVariant(product)

  return {
    event: 'productClick',
    countryCode: storefrontStore.currentMarket.countryCode,
    widgetId: getXoWidgetId(storefrontStore.currentStorefrontCode),
    sourceId: rootStore.lastEarlyBirdRecord,
    ecommerce: {
      click: {
        actionField: {
          action: 'click',
        },
        products: [
          {
            ...normalizeProductItem(product, variant, 1, listInfo, currency),
            metric1: variant.discount?.percentage || 0,
            collectionContent,
            collectionIndex,
          },
        ],
      },
    },
  }
}

export interface ProductDetailPayloadArgs {
  product: Product
}
export function productDetailPayload({ product }: ProductDetailPayloadArgs): ProductDetailEvent {
  const storefrontStore = useStorefrontStore()
  const routeStore = useRouteStore()
  const list = getListFromRoute(routeStore.lastRouteLocation) // reflect which category or page the product is clicked from
  const currency = storefrontStore.currency
  const variant = getDisplayVariant(product)

  return {
    event: 'productDetail',
    customerId: getCookie(USER_ID_COOKIE) || '',
    countryCode: storefrontStore.currentMarket.countryCode,
    ecommerce: {
      currencyCode: currency.code,
      detail: {
        products: [
          {
            ...normalizeProductItem(product, variant, 1, list, currency),
            metric1: variant.discount.percentage || 0,
          },
        ],
      },
    },
  }
}

export interface ProductImpressionPayloadArgs {
  collectionIndex: number
  listInfo: string
  product: Product
  collectionContent: boolean
  impressions: Product[]
}
export function productImpressionPayload({
  impressions,
  collectionContent,
  collectionIndex,
  listInfo,
}: ProductImpressionPayloadArgs): ProductImpressionEvent {
  const storefrontStore = useStorefrontStore()
  const route = useRoute()
  const list = [
    getListFromRoute(route), // reflect which category or page the product is impressed on
    listInfo,
  ]
    .filter(Boolean)
    .join(', ')
  const currencyCode = storefrontStore.currentMarket.currency.code

  return {
    event: 'productImpression',
    countryCode: storefrontStore.currentMarket.countryCode,
    ecommerce: {
      currencyCode,
      impressions: processImpressionData(impressions, list, collectionContent, collectionIndex),
    },
  }
}

export function generalInfoPayload(pageType: string, url: string): GeneralInfoEvent {
  const storefrontStore = useStorefrontStore()
  const cartStore = useCartStore()

  return {
    event: 'general',
    language: storefrontStore.currentMarket.language,
    locale: storefrontStore.currentMarket.locale.iso,
    customerId: getCookie(USER_ID_COOKIE) || '',
    countryCode: storefrontStore.currentMarket.countryCode,
    pageUrl: url,
    pageType,
    splitTests: getSplitTestVariants(),
    ecommerce: {
      cart: {
        products: processCartData(
          cartStore.state.items,
          storefrontStore.currentMarket.currency.code,
        ),
      },
    },
  }
}

export function clickSearchSuggestionsPayload(searchTerm: string, sku: string): ClickSearchSuggestionsEvent {
  const storefrontStore = useStorefrontStore()
  const rootStore = useRootStore()

  return {
    event: 'clickSearchSuggestions',
    sourceId: rootStore.lastEarlyBirdRecord,
    countryCode: storefrontStore.currentMarket.countryCode,
    ecommerce: {
      action: 'click',
      query: searchTerm,
      sku,
    },
  }
}

export type PageBlockClickPayloadArgs = Pick<
  PageBlockClickEvent,
  'clickURL' | 'clickType' | 'clickBlockPosition'
>
export function pageBlockClickPayload(args: PageBlockClickPayloadArgs): PageBlockClickEvent {
  const storefrontStore = useStorefrontStore()
  const route = useRoute()

  return {
    event: 'pageBlockClick',
    countryCode: storefrontStore.currentMarket.countryCode,
    pageUrl: route.path,
    routeName: route.name?.toString() || '',
    routeType: getRouteTypeFromURL(route),
    ...args,
  }
}

export type FilterClickPayloadArgs = Pick<FilterClickEvent, 'filterId' | 'filterValue' | 'activeFilters' | 'clickSource'>

export function filterClickPayload(
  args: FilterClickPayloadArgs,
): FilterClickEvent {
  const storefrontStore = useStorefrontStore()
  const rootStore = useRootStore()

  return {
    event: 'filterClick',
    countryCode: storefrontStore.currentMarket.countryCode,
    sourceId: rootStore.lastEarlyBirdRecord,
    ...args,
  }
}

export function spaPageViewPayload(to: RouteLocationNormalized): SpaPageViewEvent {
  const rootStore = useRootStore()
  const storefrontStore = useStorefrontStore()

  return {
    pageType: 'PageView',
    countryCode: storefrontStore.currentMarketCountryCode,
    sourceId: rootStore.lastEarlyBirdRecord,
    pageUrl: to.fullPath,
    event: 'SPA-PageView',
    routeName: to.name?.toString() || '',
    routeType: getRouteTypeFromURL(to),
  }
}
