import type { Variables } from '~/contracts/ProductRepositoryContract'

export function useSegment() {
  const segments = computed(() => {
    return useStoryblokStore().state.segments
  })

  const segmentVariables = useState<Variables>('segment-variables')
  const activeUtmCampaign = useState<string>('utm_campaign')
  const activeUtmMedium = useState<string>('utm_medium')
  const activeUtmContent = useState<string>('utm_content')

  const activeSegment = computed(() => {
    return segments.value?.find(segment => segment.utmCampaigns.includes(activeUtmCampaign.value) || segment.utmContents.includes(activeUtmContent.value))
  })

  const activeSegmentId = computed(() => {
    return activeSegment.value?.id
  })
  const isSubscribed = computed(() => {
    return activeUtmMedium.value === 'email'
  })

  const disableRegularNewsletter = computed(() => {
    return Boolean(activeSegment.value?.hideNewsletter)
  })

  function setSegmentVariables(variables: Variables) {
    segmentVariables.value = variables
  }

  return {
    activeSegment,
    activeSegmentId,
    isSubscribed,
    disableRegularNewsletter,
    segmentVariables,

    setSegmentVariables,
  }
}
