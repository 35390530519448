export function useFocusVisibleColor(bgColor: string) {
    const focusVisibleColor = computed(() => {
        if (bgColor === '#0066EE' || bgColor === 'var(--blue)') {
            return 'var(--white)'
        }
        else {
            return 'var(--black)'
        }
    })
    return focusVisibleColor
}
